.logoIcon {
    height: 36px;
    width: 36px;
    color: white;
}

.stickedLogoIcon {
    color: #fe2722;
}

.logoIconWrapperRedesign {
    display: flex;
    width: 48px;
    height: 48px;
    background: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

@media (--snow-tablet) {
    .logoLink {
        display: flex;
    }

    .logo {
        width: 87px;
    }

    .stickedLogo {
        width: 95px;
    }
}

@media (--snow-desktop) {
    .logo {
        width: 121px;
    }

    .logoRedesign {
        width: 91px;
    }

    .stickedLogo {
        width: 95px;
    }

    .logoIconWrapperRedesign {
        width: 115px;
    }
}
