.toast {
    font-size: 14px;
    padding: 12px;
}
.toastText {
    color: #fff;
}

.loaderWrap {
    cursor: pointer;
    min-width: 42px;
    height: 32px;
    background: inherit;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
