.iconMsiteRedesignWrapper {
    cursor: pointer;
    min-width: 42px;
    height: 32px;
    background: #eef2f6;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

:global(.searchSuggestIsOpened) .iconMsiteRedesignWrapper {
    display: none;
}

.iconMsiteRedesignWrapper.redBackground {
    background: #fe2722;
}

.iconMsiteRedesign {
    width: 16px;
    height: 16px;
    color: #6c727e;
}

.iconMsiteRedesign.whiteIcon {
    color: #fff;
}
