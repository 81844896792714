.root {
    --z-index: 11;

    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 12px;
    outline: none;
    position: fixed;
    width: 48px;
    z-index: var(--z-index);
}

.icon {
    color: #000;
    height: 20px;
    width: 20px;
}
