:root {
    --bg-color: var(--layout-settings-bg-color);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

body {
    --header-height: 72px;
}

.header {
    --z-index: 11;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;
    gap: 8px;
    height: var(--header-height);
    /* При изменении высоты в фиксированном положении не забудьте поменять переменную MOBILE_STICKY_HEADER_HEIGHT в виджете. */
    justify-content: space-between;
    padding: 10px 12px 6px 12px;
    position: sticky;
    top: 0;
    z-index: var(--z-index);
    width: 100%;

    button,
    input {
        font-family: Inter, sans-serif;
    }
}

.headerMsiteRedesign {
    --header-height: 56px;
    padding: 8px 12px;
}

.iconMsiteRedesign {
    width: 16px;
    height: 16px;
    color: #6c727e;
}

:global(.searchSuggestIsOpened) .header {
    border-radius: 0;
    position: fixed;
    width: 100%;
}

.showingHeaderAnimation {
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-name: showing;
}

/* Source: https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */
.iOSSafeAreaInsetBackground {
    background-color: #fff;
    height: 200px;
    left: 0;
    position: fixed;
    right: 0;
    top: -200px;
    z-index: 11;
}

@keyframes showing {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(68px);
    }
}

.hidingHeaderAnimation {
    animation-delay: calc(var(--redHeaderScroll) * -300ms);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-name: hiding;
    animation-play-state: paused;
    animation-timing-function: linear;
}

@keyframes hiding {
    from {
        transform: translateY(68px);
    }

    to {
        transform: translateY(0);
    }
}

.rowAligner {
    align-items: center;
    display: flex;
    flex-basis: 50%;
    justify-content: space-between;
}

.searchRowAligner {
    flex-basis: 48px;
    gap: 8px;
}

.searchRowAlignerMsiteRedesign {
    gap: 0;
}

.searchRowAlignerMsiteRedesign > div {
    margin: 0 8px 0 0;
}

.searchRowAlignerMsiteRedesign > div.iconMsiteRedesignWithMargin {
    margin: 0 4px 0 0;
}

.searchRowAlignerMsiteRedesign > div:last-child {
    margin: 0;
}

.search {
    flex-grow: 1;
}

@media (--snow-tablet) {
    body {
        --header-height: 56px;
    }

    :global(.newRedHeaderDesign) {
        --header-height: 62px;
    }

    .header {
        align-items: center;
        background-color: #fe2722;
        border: 1px solid #de2622;
        border-radius: 6px;
        flex-direction: row;
        gap: 0;
        /* При изменении высоты в фиксированном положении не забудьте поменять переменную DESKTOP_STICKY_HEADER_HEIGHT в виджете. */
        margin-top: 12px;
        padding: 8px;
        top: 0;
    }

    :global(.searchSuggestIsOpened) .header {
        border-radius: 6px;
        position: sticky;
        border: none;
        padding: 9px;
    }

    :global(.searchSuggestIsOpened) .headerRedesign {
        border: 1px solid #fe2722;
        padding: 6px;
    }

    :global(.searchSuggestIsOpened) .stickedHeaderRedesign {
        border: 1px solid #e6eaf0;
    }

    .headerRedesign {
        gap: 4px;
        padding: 6px;
        border: 1px solid #fe2722;
        --header-height: 62px;
    }

    .stickedHeader {
        background-color: #fff;
        border-color: #e6eaf0;
    }

    .stickedHeaderRedesign {
        border: 1px solid #e6eaf0;
    }

    .rowAligner {
        flex-basis: auto;
    }

    .readingLineAligner {
        padding-left: 16px;
        width: 100%;
        max-width: calc(37.5% + 4px);
        min-width: auto;
    }

    .readingLineAlignerRedesign {
        max-width: 52px;
        padding-left: 0;
    }

    .readingLineAlignerForNewSearchDesign {
        max-width: calc(-10px + 25vw);
    }

    .searchRowAligner {
        height: 100%;
        flex-grow: 1;
    }

    .searchRowAlignerRedesign {
        gap: 4px;
    }

    .navigation {
        flex-grow: 1;
    }

    .background {
        background-color: var(--bg-color);
        border-radius: 0 0 6px 6px;
        position: relative;
        width: 100%;
    }

    .widgetOverlay {
        display: none;
        background-color: rgba(34, 34, 34, 0.68);
        border-radius: 0 0 6px 6px;
        height: 100%;
        left: 0;
        opacity: 0.45;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: calc(var(--z-index) + 1);
    }

    :global(.searchSuggestIsOpened) .widgetOverlay {
        display: block;
    }

    :global(.searchSuggestIsHovered) .widgetOverlay {
        background-color: rgba(34, 34, 34, 0.64);
    }

    .marker {
        position: relative;
    }

    .zeroMarker {
        position: fixed;
        top: -1px;
    }
}

@media (--snow-desktop) {
    body {
        --header-height: 70px;
    }

    body.stickedHeader {
        --header-height: 56px;
    }

    body.stickedHeaderRedesign {
        --header-height: 70px;
    }

    :global(.newRedHeaderDesign) {
        --header-height: 70px;
    }

    .headerRedesign {
        padding: 8px;
        --header-height: 70px;
    }

    :global(.searchSuggestIsOpened) .headerRedesign {
        padding: 8px;
    }

    .readingLineAligner {
        padding-left: 16px;
        width: 306px;
    }

    .readingLineAlignerRedesign {
        padding-left: 0;
        width: 115px;
        max-width: 115px;
    }

    .readingLineAlignerForNewSearchDesign {
        width: calc(86px + 15.625vw);
    }
}

@media (min-width: 1280px) {
    .readingLineAlignerForNewSearchDesign {
        width: 286px;
    }
}
