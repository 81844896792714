.spinner {
    animation: spin 2s linear infinite;
    height: 16px;
    width: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
